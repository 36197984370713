.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
.tooltip[hoverTitle]:hover::after{
    content:attr(hoverTitle);
    position: absolute;
    width: 500px;
    top:25px;
    background-color: #cddc39;
    padding: 10px;
    color: black;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    left:-200px;
    z-index: 1;
}
.tooltip[hoverMainTitle]:hover::after{
  content:attr(hoverMainTitle);
  position: absolute;
  width: 500px;
  top:25px;

  background-color: #cddc39;
  padding: 10px;
  color: black;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  left:-150px;
  font-size: 16px;
  z-index: 1;
}